import React from 'react'
import ReactMarkdown from 'react-markdown'

import ContentWrapper from '../ContentWrapper'
import Icon from '../Icon'

import * as styles from './style.module.sass'

const Element = ({ icon, text }) => (
  <div className={styles.elementContainer}>
    <Icon icon={icon} iconSize="medium" containerSize="medium" />
    <p>{text}</p>
  </div>
)

const SelectivityProcess = () => (
  <div className={styles.container}>
    <ContentWrapper>
      <h1><ReactMarkdown children='Notre différence : **des experts testés et approuvés, une base ultra-qualifiée**'/></h1>
      <p className={styles.baseline}>Nous avons mis en place un processus de sélection qui nous permet de vous proposer les ingénieurs et techniciens les plus adaptés à votre besoin.</p>

      <div className={`${styles.stepsContainer} columns`}>
        <div className={`${styles.stepWrapper} column is-one-quarter-desktop is-half-tablet-only is-full-mobile`}>
          <div className={`${styles.step} ${styles.even}`}>
            <div className={styles.textContainer}>
              <p className={styles.position}>1</p>
              <h2>Vérification <span>des expériences</span></h2>
              <p>Chaque candidat nous fournit :</p>

              <div className={styles.elementsContainer1}>
                <div className={`${styles.elementWrapper}`}>
                    <Element icon='diplome' text='Diplôme(s)' />
                </div>
                <div className={`${styles.elementWrapper}`}>
                    <Element icon='cv' text='C.V.' />
                </div>
                <div className={`${styles.elementWrapper}`}>
                    <Element icon='references' text='References' />
                </div>
              </div>

              <p>Un membre de notre équipe vérifie toutes les informations.</p>
              <p>Le candidat peut passer à l'étape suivante s'il correspond aux exigences de qualité de la plateforme.</p>
            </div>
            <img className={styles.bottomImage1} src='/img/selectivity-process-step-1.webp' alt='bottom element' />
          </div>
        </div>

        <div className={`${styles.arrowContainer} column is-half-touch`}>
          <Icon icon='rightArrow' iconSize="large" containerSize="large" />
        </div>

        <div className={`${styles.stepWrapper} column is-one-quarter-desktop is-half-tablet-only is-full-mobile`}>
          <div className={`${styles.step} ${styles.odd}`}>
            <img className={styles.topImgage2} src='/img/selectivity-process-step-2.webp' alt='top element' />
            <div className={styles.textContainer}>
              <p className={styles.position}>2</p>
              <h2>Entretien <span>avec un expert métier</span></h2>
              <p>Le candidat passe un entretien physique ou téléphonique avec un expert de son domaine.</p>
              <p>Nous étudions :</p>

              <div className={styles.elementsContainer2}>
                <div className={`${styles.elementWrapper}`}>
                  <Element icon='books' text='Ses connaissances métiers' />
                </div>
                <div className={`${styles.elementWrapper}`}>
                  <Element icon='diamon' text='Sa personnalité' />
                </div>
                <div className={`${styles.elementWrapper}`}>
                  <Element icon='references' text='Ses motivations' />
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className={`${styles.arrowContainer} column is-half-touch`}>
          <Icon icon='rightArrow' iconSize="large" containerSize="large" />
        </div>

        <div className={`${styles.stepWrapper} column is-one-quarter-desktop is-half-tablet-only is-full-mobile`}>
          <div className={`${styles.step} ${styles.even}`}>
            <div className={`${styles.textContainer} ${styles.textContainer3}`}>
              <p className={styles.position}>3</p>
              <h2>Création et <span>qualification du profil</span></h2>

              <p>Nous accompagnons l’ingénieur dans l’élaboration de son profil.</p>
              <p>Celui-ci doit être complet afin que notre algorithme de matching catégorise le profil au mieux et apparaisse lors des recherches liées.</p>
            </div>
            <img className={styles.bottomImage3} src='/img/selectivity-process-step-3.webp' alt='bottom element' />
          </div>
        </div>
      </div>
    </ContentWrapper>
  </div>
)

export default SelectivityProcess
