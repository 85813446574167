import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'

import SelectivityProcess from '../components/SelectivityProcess'
import Benefits from '../components/Benefits'

const SelectivityPageTemplate = ({ benefitsTitle, benefitsImage, benefits, buttonText }) => {
  return (
    <>
      <SelectivityProcess />
      <Benefits
        benefitsTitle={benefitsTitle}
        benefitsImage={benefitsImage}
        benefits={benefits}
        buttonURL={`${process.env.GATSBY_APP_URL}/signup`}
        buttonText={buttonText}
      />
    </>
  )
}

SelectivityPageTemplate.propTypes = {
  benefitsTitle: PropTypes.string,
  benefitsImage: PropTypes.object,
  benefits: PropTypes.array,
  buttonURL: PropTypes.string,
  buttonText: PropTypes.string,
}

const SelectivityPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { benefitsTitle, benefitsImage, benefits, buttonURL, buttonText } = frontmatter

  return (
    <Layout>
      <Helmet>
        <title>Des centaines de profils certifiés grâce au processus de validation de notre équipe.</title>
        <meta name="description" content="Notre processus de sélection, travaillé en collaboration avec les équipes d’Artelia, nous permet de vous proposer des profils experts testés et fiables." />
        {/* Open Graph */}
        <meta name="og:title" content="Artelink - Des centaines de profils certifiés grâce au processus de validation de notre équipe." />
        <meta name="og:image" content="" />
        <meta name="og:url" content="" />
        <meta name="og:description" content="Notre processus de sélection, travaillé en collaboration avec les équipes d’Artelia, nous permet de vous proposer des profils experts testés et fiables." />
      </Helmet>

      <SelectivityPageTemplate
        benefitsTitle={benefitsTitle}
        benefitsImage={benefitsImage}
        benefits={benefits}
        buttonURL={buttonURL}
        buttonText={buttonText}
      />
    </Layout>
  )
}

SelectivityPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default SelectivityPage

export const pageQuery = graphql`
  query SelectivityPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "selectivity-page" } }) {
      frontmatter {
        benefitsTitle
        benefitsImage {
          publicURL
        }
        benefits {
          icon {
            publicURL
          }
          text
        }
        buttonURL
        buttonText
      }
    }
  }
`